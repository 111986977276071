import Joi from '@hapi/joi';
import React, { ReactElement, useState } from 'react';

import { TextInput } from 'carbon-components-react';
import './styles.scss';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { signIn, SignInOutput } from 'aws-amplify/auth';
import { Bold, ErrorMessage } from '../../components/Theme/Theme';
import { StyledBlueButton } from '../../components/Button/Button';
import validate from '../../utils/validate';

export function LoginFrame({
  setCurrentSignInStep,
}: {
  setCurrentSignInStep: React.Dispatch<
    React.SetStateAction<undefined | SignInOutput>
  >;
}): ReactElement {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLogin = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<void> => {
    try {
      const signInOutput = await signIn({ username, password });
      setCurrentSignInStep(signInOutput);
    } catch (err) {
      setErrorMessage(`
        Something went wrong. Could not log in - 
        ${err.message}
      `);
    }
  };

  return (
    <Form
      onSubmit={handleLogin}
      validate={(values) =>
        validate(
          values,
          Joi.object({
            username: Joi.string().required(),
            password: Joi.string().required(),
          })
        )
      }
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <IntroText data-cy="login-welcome-text">
            Welcome <br />
            <SmallIntro>
              Log in to <Bold>The Body Coach</Bold> to continue
            </SmallIntro>
          </IntroText>
          <Field name="username">
            {({ input, meta }) => (
              <InputWrapper>
                <TextInput
                  {...input}
                  id="username"
                  invalid={meta.error && meta.touched}
                  invalidText={meta.error}
                  labelText="Username"
                  light={false}
                  placeholder="Username"
                  size={undefined}
                  type="text"
                  data-cy="login-username-textfield"
                  data-testid="login-username-textfield"
                />
              </InputWrapper>
            )}
          </Field>
          <Field name="password">
            {({ input, meta }) => (
              <InputWrapper>
                <TextInput
                  {...input}
                  id="password"
                  invalid={meta.error && meta.touched}
                  invalidText={meta.error}
                  labelText="Password"
                  light={false}
                  placeholder="Password"
                  size={undefined}
                  type="password"
                  data-cy="login-password-textfield"
                  data-testid="login-password-textfield"
                />
              </InputWrapper>
            )}
          </Field>
          {errorMessage && (
            <ErrorMessage
              data-cy="login-error-message"
              data-testid="login-error-message"
            >
              {errorMessage}
            </ErrorMessage>
          )}
          <StyledConfirmButton
            type="submit"
            disabled={submitting}
            data-cy="login-continue-button"
            data-testid="login-continue-button"
          >
            Continue
          </StyledConfirmButton>
        </form>
      )}
    />
  );
}

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 64px auto auto;
  font-weight: bold;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 16px;
`;

const SmallIntro = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props): string => props.theme.m};
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const IntroText = styled.h1`
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props): string => props.theme.l};
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-top: 30px;
`;
