import { getAuthenticatedUser } from '../AuthHandler';
import { signOut } from 'aws-amplify/auth';

export type GQLResponse<T> = Promise<{
  data: T;
  errors?: [{ message: string }];
}>;

export const queryContentfulAPI = <T>(
  query: string,
  variables?: { [key: string]: unknown }
): GQLResponse<T> => {
  return getAuthenticatedUser().then(() => {
    return fetch(process.env.REACT_APP_CONTENTFUL_API_URL || '', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then(
        (res) => {
          if (res.status === 401 || res.status === 403) {
            signOut();
          }
          return res.json();
        },
        (err) => {
          console.log('failed on appsync' + err.toString());
        }
      )
      .catch((err) => {
        console.log('CATCH ');
        console.log(err);
      });
  });
};
