import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './styles.scss';
import styled from 'styled-components';
import BackgroundSVG from './background.svg';
import { BodyCoachSpringLogo } from './BodyCoachSpringLogo';
import { LoginFrame } from './Loginframe';
import { MfaFrame } from './MfaFrame';
import { MfaSetupFrame } from './MfaSetupFrame';
import { ContinueDialog } from '../../components/Dialogs/Continue/Continue';
import { SetPermanentPasswordFrame } from './SetPermanentPasswordFrame';
import { SignInOutput } from 'aws-amplify/auth';

export function LoginPage(props: { isLoggedIn: boolean }): ReactElement {
  const [currentSignInStep, setCurrentSignInStep] = useState<
    undefined | SignInOutput
  >();

  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const { from } = (location && location.state) || { from: { pathname: '/' } };

  useEffect(() => {
    if (props.isLoggedIn) {
      history.replace(from);
    }
  }, [from, history, props.isLoggedIn]);

  return (
    <Wrapper>
      <LoginBox>
        <LogoWrapper data-cy="login-logo">
          <BodyCoachSpringLogo />
        </LogoWrapper>
        {currentSignInStep?.nextStep.signInStep ===
          'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED' && (
          <SetPermanentPasswordFrame
            setCurrentSignInStep={setCurrentSignInStep}
          />
        )}
        {currentSignInStep?.nextStep.signInStep ===
          'CONTINUE_SIGN_IN_WITH_TOTP_SETUP' && (
          <MfaSetupFrame
            currentStep={currentSignInStep}
            setCurrentSignInStep={setCurrentSignInStep}
          />
        )}
        {currentSignInStep?.nextStep.signInStep ===
          'CONFIRM_SIGN_IN_WITH_TOTP_CODE' && (
          <MfaFrame setCurrentSignInStep={setCurrentSignInStep} />
        )}
        {currentSignInStep === undefined && (
          <LoginFrame setCurrentSignInStep={setCurrentSignInStep} />
        )}
        {currentSignInStep?.nextStep.signInStep === 'DONE' && (
          <ContinueDialog
            headline="Account set-up successfully"
            continueButtonText="Continue"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            continueButtonCallback={(): void => {}}
            continueToPath="/"
          />
        )}
      </LoginBox>
    </Wrapper>
  );
}

const LogoWrapper = styled.div`
  margin-top: 52px;
`;

const Wrapper = styled.div`
  display: flex;
  background-image: url(${BackgroundSVG});
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  .bx--form-item {
    flex: inherit;
  }
`;

const LoginBox = styled.div`
  display: flex;
  background-color: ${(props): string => props.theme.white};
  max-width: 362px;
  flex-direction: column;
  width: 362px;
  height: auto;
  border-radius: 10px;
  padding: 34px;
  text-align: center;
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;
