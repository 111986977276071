import React, { ReactElement, useEffect, useState } from 'react';
import { Routes } from './Router';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Loader } from './components/Loader/Loader';

export const getAuthenticatedUser = async () => {
  const { username, signInDetails } = await getCurrentUser();

  const { tokens: session } = await fetchAuthSession();

  return {
    username,
    session,
    authenticationFlowType: signInDetails?.authFlowType,
  };
};

export function AuthHandler(): ReactElement {
  const [isLoadingAuthState, setIsLoadingAuthState] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      getAuthenticatedUser()
        .then(() => {
          setIsLoggedIn(true);
          setIsLoadingAuthState(false);
        })
        .catch(() => {
          setIsLoggedIn(false);
          setIsLoadingAuthState(false);
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedOut') {
        setIsLoggedIn(false);
        setIsLoadingAuthState(false);
      }
      if (payload.event === 'signedIn') {
        setIsLoggedIn(true);
        setIsLoadingAuthState(false);
      }
    });
  }, []);

  if (isLoadingAuthState) return <Loader />;

  return <Routes isLoggedIn={isLoggedIn} />;
}
