import Joi from '@hapi/joi';
import React, { ReactElement, useState } from 'react';

import { TextInput } from 'carbon-components-react';
import './styles.scss';
import styled from 'styled-components';
import { confirmSignIn, SignInOutput } from 'aws-amplify/auth';
import { Form, Field } from 'react-final-form';
import { ErrorMessage } from '../../components/Theme/Theme';
import validate from '../../utils/validate';
import { StyledBlueButton } from '../../components/Button/Button';
import { ValidationErrors } from 'final-form';

export function SetPermanentPasswordFrame(props: {
  setCurrentSignInStep: React.Dispatch<
    React.SetStateAction<undefined | SignInOutput>
  >;
}): ReactElement {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSetNewPassword = ({
    newpassword,
  }: {
    newpassword: string;
  }): void => {
    confirmSignIn({ challengeResponse: newpassword })
      .then((result) => {
        props.setCurrentSignInStep(result);
      })
      .catch((err) => {
        setErrorMessage(`
          Something went wrong. Could not set new password -
          ${err.message}
        `);
      });
  };

  return (
    <>
      <Form
        onSubmit={handleSetNewPassword}
        validate={(
          values
        ): ValidationErrors | Promise<ValidationErrors> | undefined =>
          validate(
            values,
            Joi.object({
              newpassword: Joi.string().required(),
              confirmpassword: Joi.string()
                .required()
                .valid(Joi.ref('newpassword')),
            })
          )
        }
        render={({ handleSubmit, submitting }): ReactElement => (
          <form onSubmit={handleSubmit}>
            <IntroText>
              Set your password <br />
              <SmallIntro>
                This will be the password you use to log in from now on
              </SmallIntro>
            </IntroText>
            <Field name="newpassword">
              {({ input, meta }): ReactElement => (
                <>
                  <InputWrapper>
                    <TextInput
                      {...input}
                      id="newpassword"
                      invalid={meta.error && meta.touched}
                      invalidText="Password must be set"
                      labelText="New password"
                      light={false}
                      placeholder="Enter new password"
                      size={undefined}
                      type="password"
                      data-cy="permanent-password-textfield"
                      required
                    />
                  </InputWrapper>
                </>
              )}
            </Field>
            <Field name="confirmpassword">
              {({ input, meta }): ReactElement => (
                <>
                  <InputWrapper>
                    <TextInput
                      {...input}
                      id="confirmpassword"
                      invalid={meta.error && meta.touched}
                      invalidText="Passwords must match"
                      labelText="Confirm new password"
                      light={false}
                      placeholder="Confirm new password"
                      size={undefined}
                      type="password"
                      data-cy="permanent-password-confirm-textfield"
                      required
                    />
                  </InputWrapper>
                </>
              )}
            </Field>
            <PasswordPolicy>
              <PolicyStatement>
                Passwords must be a minimum of 14 characters.
              </PolicyStatement>
              <PolicyStatement>
                Passwords must contain at least one:
              </PolicyStatement>
              <PasswordRequirements>
                <li>Lower case character</li>
                <li>Upper case character</li>
                <li>Number</li>
                <li>Special character</li>
              </PasswordRequirements>
            </PasswordPolicy>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <StyledConfirmButton
              disabled={submitting}
              type="submit"
              data-cy="permanent-password-save-button"
            >
              Save password
            </StyledConfirmButton>
          </form>
        )}
      />
    </>
  );
}

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 20px auto auto;
  font-weight: bold;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 30px;
`;

const SmallIntro = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const IntroText = styled.h1`
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props): string => props.theme.l};
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-top: 30px;
`;

const PasswordPolicy = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Avenir Next, sans-serif;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const PolicyStatement = styled.p`
  font-size: ${(props): string => props.theme.s};
`;

const PasswordRequirements = styled.ul`
  margin-top: 10px;
  font-size: ${(props): string => props.theme.s};
  width: 60%;
  list-style: disc;
  text-align: left;
`;
