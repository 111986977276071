import { z } from 'zod';

const cognitoAuthSchema = z.object({
  userPoolId: z.string().min(1),
  userPoolClientId: z.string().min(1),
});

export const cognitoAuthConfig = cognitoAuthSchema.parse({
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
});
